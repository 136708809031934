import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'

import ArrowLink from './icons/arrow-link'
import CaretRight from './icons/caret-right'
import Facebook from './icons/facebook'
import Linkedin from './icons/linkedin'
import Youtube from './icons/youtube'

import { Container } from '../styles/PageLayout'
import { ParagraphL, ParagraphS, ParagraphXS } from '../styles/Typography'
import { ButtonPrimary } from '../styles/components/ButtonStyled'
import { ContactSectionStyled, FooterStyled } from '../styles/components/FooterStyled'
import { theme } from '../constants/ui/theme'

const t = {
  en: {
    title: 'Need help with the cosmetics regulations and testing?',
    contactUs: 'Contact us',
    euRegulatory: 'EU & UK Regulatory',
    otherMarkets: 'Other Markets Regulatory',
    canada: 'Canada',
    china: 'China',
    saudi: 'Saudi Arabia',
    otherCountries: 'Other Countries',
    safetyTesting: 'Safety Testing',
    stabilityTest: 'Stability and Compatibility Test',
    challengeTest: 'Challenge Test',
    microTesting: 'Microbiology Testing',
    cpsr: 'CPSR',
    patchTest: 'Patch Test',
    efficacyTesting: 'Efficacy Testing',
    hairCare: 'Hair care',
    skinCare: 'Skin care and protection',
    sunProtection: 'Sun protection',
    oralCareMakeUp: 'Oral care and make-up',
    ingredients: 'Ingredients',
    toxProfiles: 'Toxicological profiles',
    aboutUs: 'About us',
    news: 'News',
    shop: 'Shop',
    privacyPolicy: 'Privacy policy',
    copyright: 'Copyright',
    usa: 'USA',
    usaOtc: 'USA OTC',
    links: {
      contactUs: '/contact',
      euRegulatory: '/cosmetics-regulatory',
      usa: '/international-services/usa',
      usaOtc: '/international-services/usa-otc',
      asean: '/international-services/asean',
      saudi: '/international-services/saudi-arabia',
      uae: '/international-services/uae',
      canada: '/international-services/canada',
      china: '/international-services/china',
      otherCountries: '/international-services/other-countries',
      stabilityTest: '/cosmetics-regulatory-services/stability-compatibility-test',
      challengeTest: '/cosmetics-regulatory-services/challenge-test',
      microTesting: '/cosmetics-testing/microbiology-testing',
      cpsr: '/cosmetics-regulatory-services/cosmetics-safety-assessment-cpsr',
      sds: '/cosmetics-testing/safety-data-sheet-sds',
      patchTest: '/cosmetics-testing/patch-test',
      spf: '/cosmetics-testing/sun-protection-factor-determination-spf',
      hairCare: '/cosmetics-testing#hair-care',
      skinCare: '/cosmetics-testing#skin-care',
      sunProtection: '/cosmetics-testing#sun-protection',
      oralCareMakeUp: '/cosmetics-testing#oral-care-make-up',
      toxProfiles: '/cosmetics-testing/ingredient-toxicological-profiles',
      aboutUs: '/about-us',
      faq: '/faq-cosmetics',
      shop: '/shop',
      privacyPolicy: '/privacy-policy',
    },
  },
  es: {
    title: '¿Necesitas ayuda con las regulaciones y pruebas de cosméticos?',
    contactUs: 'Contáctanos',
    euRegulatory: 'Regulación de la UE y Reino Unido',
    otherMarkets: 'Regulación en otros mercados',
    canada: 'Canadá',
    china: 'China',
    saudi: 'Arabia Saudita',
    otherCountries: 'Otros países',
    safetyTesting: 'Pruebas de seguridad',
    stabilityTest: 'Prueba de estabilidad y compatibilidad',
    challengeTest: 'Prueba de desafío',
    microTesting: 'Pruebas de microbiología',
    cpsr: 'CPSR',
    patchTest: 'Prueba de parche',
    efficacyTesting: 'Pruebas de eficacia',
    hairCare: 'Cuidado del cabello',
    skinCare: 'Cuidado y protección de la piel',
    sunProtection: 'Protección solar',
    oralCareMakeUp: 'Cuidado bucal y maquillaje',
    ingredients: 'Ingredientes',
    toxProfiles: 'Perfiles toxicológicos',
    aboutUs: 'Sobre nosotros',
    news: 'Noticias',
    shop: 'Tienda',
    privacyPolicy: 'Política de privacidad',
    copyright: 'Copyright',
    usa: 'Estados Unidos',
    usaOtc: 'Estados Unidos OTC',
    links: {
      contactUs: '/es/contact',
      euRegulatory: '/es/cosmetics-regulatory',
      usa: '/es/international-services/usa',
      usaOtc: '/es/international-services/usa-otc',
      asean: '/es/international-services/asean',
      saudi: '/es/international-services/saudi-arabia',
      uae: '/es/international-services/uae',
      canada: '/es/international-services/canada',
      china: '/es/international-services/china',
      otherCountries: '/es/international-services/other-countries',
      stabilityTest: '/es/cosmetics-regulatory-services/stability-compatibility-test',
      challengeTest: '/es/cosmetics-regulatory-services/challenge-test',
      microTesting: '/es/cosmetics-testing/microbiology-testing',
      cpsr: '/es/cosmetics-regulatory-services/cosmetics-safety-assessment-cpsr',
      sds: '/es/cosmetics-testing/safety-data-sheet-sds',
      patchTest: '/es/cosmetics-testing/patch-test',
      spf: '/es/cosmetics-testing/sun-protection-factor-determination-spf',
      hairCare: '/es/cosmetics-testing#hair-care',
      skinCare: '/es/cosmetics-testing#skin-care',
      sunProtection: '/es/cosmetics-testing#sun-protection',
      oralCareMakeUp: '/es/cosmetics-testing#oral-care-make-up',
      toxProfiles: '/es/cosmetics-testing/ingredient-toxicological-profiles',
      aboutUs: '/es/about-us',
      faq: '/es/faq-cosmetics',
      shop: '/es/shop',
      privacyPolicy: '/es/privacy-policy',
    },
  },
  id: {
    title: 'Butuh bantuan dengan regulasi dan pengujian kosmetik?',
    contactUs: 'Hubungi kami',
    euRegulatory: 'Regulasi UE & UK',
    otherMarkets: 'Regulasi Lain',
    canada: 'Kanada',
    china: 'China',
    saudi: 'Arab Saudi',
    otherCountries: 'Negara Lain',
    safetyTesting: 'Safety Testing',
    stabilityTest: 'Uji Stabilitas dan Kompatibilitas',
    challengeTest: 'Challenge Test',
    microTesting: 'Pengujian Mikrobiologi',
    cpsr: 'CPSR',
    patchTest: 'Patch Test',
    efficacyTesting: 'Pengujian Efikasi',
    hairCare: 'Perawatan Rambut',
    skinCare: 'Perawatan dan Perlindungan Kulit',
    sunProtection: 'Perlindungan Matahari',
    oralCareMakeUp: 'Perawatan Mulut dan Make-up',
    ingredients: 'Bahan',
    toxProfiles: 'Profil Toksikologi',
    aboutUs: 'Tentang kami',
    news: 'Berita',
    shop: 'Shop',
    privacyPolicy: 'Kebijakan Privasi',
    copyright: 'Hak Cipta',
    usa: 'USA',
    usaOtc: 'USA OTC',
    links: {
      contactUs: '/id/contact',
      euRegulatory: '/id/cosmetics-regulatory',
      usa: '/id/international-services/usa',
      usaOtc: '/id/international-services/usa-otc',
      asean: '/id/international-services/asean',
      saudi: '/id/international-services/saudi-arabia',
      uae: '/id/international-services/uae',
      canada: '/id/international-services/canada',
      china: '/id/international-services/china',
      otherCountries: '/id/international-services/other-countries',
      stabilityTest: '/id/cosmetics-regulatory-services/stability-compatibility-test',
      challengeTest: '/id/cosmetics-regulatory-services/challenge-test',
      microTesting: '/id/cosmetics-testing/microbiology-testing',
      cpsr: '/id/cosmetics-regulatory-services/cosmetics-safety-assessment-cpsr',
      sds: '/id/cosmetics-testing/safety-data-sheet-sds',
      patchTest: '/id/cosmetics-testing/patch-test',
      spf: '/id/cosmetics-testing/sun-protection-factor-determination-spf',
      hairCare: '/id/cosmetics-testing#hair-care',
      skinCare: '/id/cosmetics-testing#skin-care',
      sunProtection: '/id/cosmetics-testing#sun-protection',
      oralCareMakeUp: '/id/cosmetics-testing#oral-care-make-up',
      toxProfiles: '/id/cosmetics-testing/ingredient-toxicological-profiles',
      aboutUs: '/id/about-us',
      faq: '/id/faq-cosmetics',
      shop: '/id/shop',
      privacyPolicy: '/id/privacy-policy',
    },
  },
}
/**
 * The main footer of the website.
 * @returns {StatelessComponent}
 */
const Footer = ({ langKey = 'en' }) => {
  const lang = Object.keys(t).includes(langKey) ? langKey : 'en'

  return (
    <>
      <ContactSectionStyled>
        <Container mobileColumn alignItems='center' justifyContent='space-between'>
          <ParagraphL color={theme.textLight} weight={700}>
            {t[lang]?.title}
          </ParagraphL>
          <Link to={t[lang]?.links?.contactUs} className='contact-btn'>
            <ButtonPrimary>
              <CaretRight /> {t[lang]?.contactUs}
            </ButtonPrimary>
          </Link>
        </Container>
      </ContactSectionStyled>
      <FooterStyled>
        <Container column mobileColumn>
          <div className='links'>
            <div className='links-group'>
              <Link to={t[lang]?.links?.euRegulatory}>
                <div className='links-group-title'>
                  <ParagraphS>{t[lang]?.euRegulatory}</ParagraphS>
                </div>
              </Link>
            </div>
            <div className='links-group'>
              <div className='links-group-title'>
                <ParagraphS>{t[lang]?.otherMarkets}</ParagraphS>
              </div>
              <Link to={t[lang]?.links?.usa}>
                <div className='link-item'>
                  <ParagraphS weight={400}>{t[lang]?.usa}</ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
              <Link to={t[lang]?.links?.usaOtc}>
                <div className='link-item'>
                  <ParagraphS weight={400}>{t[lang]?.usaOtc}</ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
              <Link to={t[lang]?.links?.canada}>
                <div className='link-item'>
                  <ParagraphS weight={400}>{t[lang]?.canada}</ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
              <Link to={t[lang]?.links?.asean}>
                <div className='link-item'>
                  <ParagraphS weight={400}>ASEAN</ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
              <Link to={t[lang]?.links?.china}>
                <div className='link-item'>
                  <ParagraphS weight={400}>{t[lang]?.china}</ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
              <Link to={t[lang]?.links?.saudi}>
                <div className='link-item'>
                  <ParagraphS weight={400}>{t[lang]?.saudi}</ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
              <Link to={t[lang]?.links?.uae}>
                <div className='link-item'>
                  <ParagraphS weight={400}>UAE</ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
              <Link to={t[lang]?.links?.otherCountries}>
                <div className='link-item'>
                  <ParagraphS weight={400}>{t[lang]?.otherCountries}</ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
            </div>
            <div className='links-group'>
              <div className='links-group-title'>
                <ParagraphS>{t[lang]?.safetyTesting}</ParagraphS>
              </div>
              <Link to={t[lang]?.links?.stabilityTest}>
                <div className='link-item'>
                  <ParagraphS weight={400} className='text-wrap'>
                    {t[lang]?.stabilityTest}
                  </ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
              <Link to={t[lang]?.links?.challengeTest}>
                <div className='link-item'>
                  <ParagraphS weight={400}>{t[lang]?.challengeTest}</ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
              <Link to={t[lang]?.links?.microTesting}>
                <div className='link-item'>
                  <ParagraphS weight={400}>{t[lang]?.microTesting}</ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
              <Link to={t[lang]?.links?.cpsr}>
                <div className='link-item'>
                  <ParagraphS weight={400}>CPSR</ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
              <Link to={t[lang]?.links?.sds}>
                <div className='link-item'>
                  <ParagraphS weight={400}>SDS</ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
              <Link to={t[lang]?.links?.patchTest}>
                <div className='link-item'>
                  <ParagraphS weight={400}>{t[lang]?.patchTest}</ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
              <Link to={t[lang]?.links?.spf}>
                <div className='link-item'>
                  <ParagraphS weight={400}>SPF</ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
            </div>
            <div className='links-group'>
              <div className='links-group-title'>
                <ParagraphS>{t[lang]?.efficacyTesting}</ParagraphS>
              </div>
              <Link to={t[lang]?.links?.hairCare}>
                <div className='link-item'>
                  <ParagraphS weight={400}>{t[lang]?.hairCare}</ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
              <Link to={t[lang]?.links?.skinCare}>
                <div className='link-item'>
                  <ParagraphS weight={400} className='text-wrap'>
                    {t[lang]?.skinCare}
                  </ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
              <Link to={t[lang]?.links?.sunProtection}>
                <div className='link-item'>
                  <ParagraphS weight={400}>{t[lang]?.sunProtection}</ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
              <Link to={t[lang]?.links?.oralCareMakeUp}>
                <div className='link-item'>
                  <ParagraphS weight={400}>{t[lang]?.oralCareMakeUp}</ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
            </div>
            <div className='links-group'>
              <div className='links-group-title'>
                <ParagraphS>{t[lang]?.ingredients}</ParagraphS>
              </div>
              <Link to={t[lang]?.links?.sds}>
                <div className='link-item'>
                  <ParagraphS weight={400}>SDS</ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
              <Link to={t[lang]?.links?.toxProfiles}>
                <div className='link-item'>
                  <ParagraphS weight={400}>{t[lang]?.toxProfiles}</ParagraphS>
                  <ArrowLink />
                </div>
              </Link>
            </div>
          </div>
          <div className='info'>
            <div className='info-group'>
              <Link to={t[lang]?.links?.aboutUs}>
                <ParagraphS>{t[lang]?.aboutUs}</ParagraphS>
              </Link>
              <a href='https://news.ceway.eu'>
                <ParagraphS>{t[lang]?.news}</ParagraphS>
              </a>
              <Link to={t[lang]?.links?.faq}>
                <ParagraphS>FAQ</ParagraphS>
              </Link>
              <Link to={t[lang]?.links?.shop}>
                <ParagraphS>{t[lang]?.shop}</ParagraphS>
              </Link>
            </div>
            <div className='social'>
              <a href='https://www.facebook.com/CEway' target='_blank'>
                <Facebook />
              </a>
              <a
                href='https://www.linkedin.com/company/ce-way-regulatory-consultants-ltd/'
                target='_blank'
              >
                <Linkedin />
              </a>
              <a href='https://www.youtube.com/channel/UCN9Sm6sxHL_4q0fqnUsN4CQ' target='_blank'>
                <Youtube />
              </a>
            </div>
          </div>
          <div className='privacy'>
            <div className='privacy-content'>
              <Link to={t[lang]?.links?.privacyPolicy}>
                <ParagraphS weight={600} className='privacy-title'>
                  {t[lang]?.privacyPolicy}
                </ParagraphS>
              </Link>
            </div>
            <div className='copyright'>
              <ParagraphXS>
                © {t[lang]?.copyright} {moment().year()} - ceway.eu
              </ParagraphXS>
            </div>
          </div>
        </Container>
      </FooterStyled>
    </>
  )
}

export default Footer
