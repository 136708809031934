import React from 'react'
import CookieConsent from 'react-cookie-consent'

import { theme } from 'constants/ui/theme'

import { ParagraphXS } from 'styles/Typography'
import { CookiesStyled } from 'styles/components/CookiesStyled'
import { GatsbyLinkStyled } from 'styles/components/LinkStyled'

const t = {
  en: {
    buttonText: 'Continue browsing',
    content1:
      "We use cookies that, through the use of statistics, help us make your experience here one of the best. If you don't like them, you can disable them or find out more about them in our",
    cookiesPolicy: 'Cookies Policy',
    cookiesPolicyLink: '/cookies-policy',
    content2:
      'If you continue to browse this page without changing any settings, we will interpret that as your complete consent to the use of cookies.',
  },
  es: {
    buttonText: 'Continuar navegando',
    content1:
      'Utilizamos cookies que, mediante el uso de estadísticas, nos ayudan a hacer de tu experiencia aquí una de las mejores. Si no te gustan, puedes desactivarlas o informarte más sobre ellas en nuestra',
    cookiesPolicy: 'Política de Cookies',
    cookiesPolicyLink: '/es/cookies-policy',
    content2:
      'Si continúas navegando por esta página sin cambiar ninguna configuración, interpretaremos eso como tu consentimiento completo al uso de cookies.',
  },
  id: {
    buttonText: 'Lanjutkan menjelajah',
    content1:
      'Kami menggunakan cookie yang, melalui penggunaan statistik, membantu kami membuat pengalaman Anda di sini menjadi salah satu yang terbaik. Jika Anda tidak menyukainya, Anda dapat menonaktifkannya atau mencari tahu lebih lanjut tentangnya di',
    cookiesPolicy: 'Kebijakan Cookie',
    cookiesPolicyLink: '/id/kebijakan-cookie',
    content2:
      'Jika Anda terus menjelajahi halaman ini tanpa mengubah pengaturan apa pun, kami akan menganggap itu sebagai persetujuan penuh Anda terhadap penggunaan cookie.',
  },
}

/**
 * The cookies banner.
 * @param {ComponentProps} props
 * @returns {FunctionComponent}
 */
const Cookies = ({ langKey = 'en' }) => {
  const lang = Object.keys(t).includes(langKey) ? langKey : 'en'

  return (
    <CookiesStyled>
      <CookieConsent
        buttonText={t[lang]?.buttonText}
        buttonClasses='btn'
        contentClasses='content'
        cookieName='gatsby-gdpr-google-analytics'
      >
        <ParagraphXS color={theme.textLight}>
          {t[lang]?.content1}{' '}
          <GatsbyLinkStyled to={t[lang]?.cookiesPolicyLink}>
            {t[lang]?.cookiesPolicy}
          </GatsbyLinkStyled>
          . {t[lang]?.content2}
        </ParagraphXS>
      </CookieConsent>
    </CookiesStyled>
  )
}

export default Cookies
