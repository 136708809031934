import React, { useState } from 'react'
import { useLocation } from '@reach/router'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu'
import Globe from './icons/globe'
import { Link } from 'gatsby'

const LanguageSwitcherMobile = () => {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const pathname = location.pathname
  const pathSegments = pathname?.split('/').filter(Boolean)
  const pathTarget =
    pathSegments[0] && pathSegments[0].length === 2
      ? pathSegments.slice(1).join('/')
      : pathSegments.slice(0).join('/')

  const handleSelect = (lang) => {
    setOpen(false)
    window.location.href = `/${lang}${pathTarget || ''}`
  }

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger>
        <button className='p-4 rounded-full hover:bg-gray-100'>
          <Globe />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <div className='rounded-lg border bg-white mx-4 p-2 space-y-2'>
          <DropdownMenuItem onSelect={() => handleSelect('')}>English</DropdownMenuItem>
          <DropdownMenuItem onSelect={() => handleSelect('es/')}>Español</DropdownMenuItem>
          <DropdownMenuItem onSelect={() => handleSelect('id/')}>Español</DropdownMenuItem>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default LanguageSwitcherMobile
